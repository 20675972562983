.Paragraph {
  max-width: 732px;
  font-size: 18px;
  font-weight: 400;
  font-family: $muli;
  text-align: center;
  line-height: 1.5;
  
  &.darkgrey {
    color: $darkgrey;
  }
  
  &.white {
    color: white;
  }

  &.center {
    margin: 0 auto;
    text-align: center;
    line-height: 1.5;
  }
  
  @include desktop {
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    line-height: 26px;
  }

}
