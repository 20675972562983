@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

*,
*:before,
*:after {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: inherit;
  margin: 0;
  font-family: Muli, system-ui, sans-serif;
  font-weight: 400;
}

p {
  margin: 0;
  padding: 0;
}

.slick-slide {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.slick-dots li button::before {
  font-size: 40px;
  color: #fff;
}

.slick-dots li.slick-active button::before {
  color: #fff;
  opacity: 1;
}

.Title {
  margin: 0 0 24px;
  font-family: Barlow, system-ui, sans-serif;
  font-weight: 800;
  font-size: 24px;
  text-align: center;
  line-height: 34px;
}

.Title.darkgrey {
  color: #505050;
}

.Title.white {
  color: white;
}

@media (min-width: 1000px) {
  .Title {
    font-family: Barlow, system-ui, sans-serif;
    font-weight: 800;
    font-size: 62px;
    color: #555555;
    letter-spacing: 0;
    line-height: 80px;
  }
}

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 214px;
  padding: 13px 26px;
  font-family: Muli, system-ui, sans-serif;
  font-weight: 800;
  font-size: 16px;
  color: #505050;
  border: solid 2px transparent;
  border-radius: 32px;
  text-decoration: none;
  outline: 0;
  transition: all 200ms;
}

.Button[disabled] {
  pointer-events: none;
  opacity: 0.3;
}

.Button[disabled].success {
  color: green;
}

.Button.auto {
  min-width: auto;
}

.Button.primary {
  background-color: #FBD820;
  color: #29286D;
  box-shadow: 10px 10px 12px 0 rgba(0, 0, 0, 0.2);
}

.Button.primary:hover {
  border: solid 4px white;
  border-width: 0 0 4px 0;
  background-color: #f0cb04;
}

.Button.primary:active {
  background-color: #cbab04;
}

.Button.secondary {
  color: white;
  border-color: currentColor;
}

.Button.secondary:hover {
  border-bottom-width: 4px;
}

.Button.secondary:active {
  background-color: #4387FF;
}

.Button.tertiary {
  color: #374E85;
  border-color: currentColor;
}

.Button.tertiary:hover {
  border-bottom-width: 4px;
}

.Button.tertiary:active {
  background-color: #4387FF;
}

.Button.big {
  min-width: 282px;
  padding: 18px 13px;
  font-size: 20px;
  border-radius: 32px;
}

@media (min-width: 1000px) {
  .Button.big {
    min-width: 300px;
  }
}

.ButtonSubmit {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 214px;
  padding: 13px 26px;
  font-family: Muli, system-ui, sans-serif;
  font-weight: 800;
  font-size: 16px;
  color: #505050;
  border: solid 2px transparent;
  border-radius: 32px;
  text-decoration: none;
  outline: 0;
  transition: all 200ms;
}

.ButtonSubmit[disabled] {
  pointer-events: none;
  opacity: 0.3;
}

.ButtonSubmit[disabled].success {
  color: green;
}

.ButtonSubmit.auto {
  min-width: auto;
}

.ButtonSubmit.primary {
  background-color: #FBD820;
  color: #29286D;
  box-shadow: 10px 10px 12px 0 rgba(0, 0, 0, 0.2);
}

.ButtonSubmit.primary:hover {
  border: solid 4px white;
  border-width: 0 1px 4px 1px;
  background-color: #f0cb04;
}

.ButtonSubmit.primary:active {
  background-color: #cbab04;
}

.ButtonSubmit.secondary {
  color: white;
  border-color: currentColor;
}

.ButtonSubmit.secondary:hover {
  border-bottom-width: 4px;
}

.ButtonSubmit.secondary:active {
  background-color: #4387FF;
}

.ButtonSubmit.tertiary {
  color: #374E85;
  border-color: currentColor;
}

.ButtonSubmit.tertiary:hover {
  border-bottom-width: 4px;
}

.ButtonSubmit.tertiary:active {
  background-color: #4387FF;
}

.ButtonSubmit.big {
  min-width: 282px;
  padding: 18px 13px;
  font-size: 20px;
  border-radius: 32px;
}

@media (min-width: 1000px) {
  .ButtonSubmit.big {
    min-width: 300px;
  }
}

.hero-form {
  position: relative;
  height: 80px;
  margin-top: 41px;
}

.hero-form input {
  height: 60px;
  width: 100%;
  font-family: Muli, system-ui, sans-serif;
  font-size: 15px;
  color: #9b9b9b;
  letter-spacing: 0.5px;
  border-radius: 40px;
  border: 0;
  outline: 0;
  padding: 21px 10px 21px 36px;
}

@media (min-width: 1250px) {
  .hero-form input {
    height: 100%;
    font-size: 21px;
  }
}

.hero-form .register-button {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 50px;
  border-radius: 40px;
}

@media (max-width: 1249px) {
  .hero-form .register-button {
    min-width: 75px;
    height: 60px;
    text-indent: -100px;
    color: #FBD820;
    overflow: hidden;
  }
  .hero-form .register-button::before {
    content: '';
    display: block;
    width: 23px;
    height: 18px;
    background: url("/images/email-icon.svg");
  }
}

.hero-form-response {
  margin-top: 10px;
  margin-left: 10px;
}

.Paragraph {
  max-width: 732px;
  font-size: 18px;
  font-weight: 400;
  font-family: Muli, system-ui, sans-serif;
  text-align: center;
  line-height: 1.5;
}

.Paragraph.darkgrey {
  color: #505050;
}

.Paragraph.white {
  color: white;
}

.Paragraph.center {
  margin: 0 auto;
  text-align: center;
  line-height: 1.5;
}

@media (min-width: 1000px) {
  .Paragraph {
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    line-height: 26px;
  }
}

.UsersCounter {
  margin-top: 35px;
  font-family: Muli, system-ui, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  color: #fff;
}

.UsersCounter .counter {
  font-weight: 800;
}

.UsersCounter .users {
  margin-left: 25px;
  display: none;
}

@media (min-width: 1000px) {
  .UsersCounter .users {
    display: inline-block;
  }
}

.UsersCounter .users::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 13px;
  height: 13px;
  line-height: 13px;
  background: #ABFB20;
  border-radius: 100%;
  margin-right: 10px;
  margin-bottom: 2px;
}

.contact-modal {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  padding: 90px 15px 42px;
  z-index: 9999;
  transition: all .3s ease;
}

.contact-modal.active {
  opacity: 1;
  pointer-events: all;
}

@media (min-width: 768px) {
  .contact-modal {
    width: 740px;
    height: auto;
    left: 50%;
    top: 50%;
    right: inherit;
    bottom: inherit;
    border-radius: 12px;
    transform: translate(-50%, -50%);
    padding: 90px 150px 40px;
  }
}

.contact-modal .Title {
  font-size: 32px;
}

@media (min-width: 1000px) {
  .contact-modal .Title {
    font-size: 62px;
  }
}

.contact-modal .close-icon {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 35px;
  top: 35px;
  background: url("/images/close-icon.svg") no-repeat center;
  z-index: 99999;
}

.contact-modal .contact-form .group {
  position: relative;
  display: inline-block;
  font-family: Muli, system-ui, sans-serif;
  width: 100%;
  margin: 15px 0;
}

@media (min-width: 1000px) {
  .contact-modal .contact-form .group {
    margin: 20px 0;
  }
}

.contact-modal .contact-form label {
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 18px;
  color: #bababa;
  transform: translateY(-50%);
  transition: all .15s ease-out 0s;
}

.contact-modal .contact-form input {
  font-size: 18px;
  display: inline-block;
  background: none;
  padding: 5px 0;
  font-size: 18px;
  color: #555555;
  width: 100%;
}

.contact-modal .contact-form input[type="email"], .contact-modal .contact-form input[type="text"] {
  border: none;
  border-bottom: 1px solid #bababa;
}

.contact-modal .contact-form input:focus, .contact-modal .contact-form input:active {
  outline: none;
}

.contact-modal .contact-form input[disabled] {
  pointer-events: none;
  opacity: 0.3;
}

.contact-modal .contact-form input[disabled].success {
  color: green;
}

.contact-modal .contact-form input.error {
  border-bottom: 1px solid #ff4343;
}

.contact-modal .contact-form #mc_embed_signup div.mce_inline_error {
  color: #ff4343;
  background: none;
  font-size: 14px;
  font-weight: 300;
  margin: 5px 0;
  padding: 0;
  height: 0;
}

.contact-modal .contact-form #mc_embed_signup input.mce_inline_error {
  border-color: #ff4343;
}

.contact-modal .contact-form .input-text {
  border: none;
  border-bottom: 1px solid #bababa;
}

.contact-modal .contact-form .group input:focus + label,
.contact-modal .contact-form .group input.has-value + label {
  font-size: 14px;
  top: -25%;
}

.contact-modal .contact-form select {
  font-family: Muli, system-ui, sans-serif;
  font-size: 18px;
  width: 100%;
  padding: 5px 0;
  border-radius: 0;
  background: none;
  padding: 5px 0;
  margin: 0;
  outline: 0;
  border: 0;
  color: #555555;
  border-bottom: 1px solid #bababa;
  -webkit-appearance: none;
}

.contact-modal .contact-form .dropdown {
  position: relative;
  top: 30%;
}

.contact-modal .contact-form .dropdown-group::after {
  content: '';
  display: block;
  position: absolute;
  height: 20px;
  width: 20px;
  right: 0;
  top: 25%;
  background: url("/images/arrow_down.svg") no-repeat center;
}

.contact-modal .contact-form .dropdown-label.has-value {
  top: -30%;
}

.contact-modal .contact-form .response {
  margin: 20px 0;
}

.contact-modal .contact-form input[type="submit"] {
  display: block;
  min-width: 214px;
  padding: 13px 26px;
  margin: 50px auto 23px;
  font-family: Muli, system-ui, sans-serif;
  font-weight: 800;
  font-size: 16px;
  color: #505050;
  border: solid 2px transparent;
  border-radius: 32px;
  text-decoration: none;
  outline: 0;
  transition: all 200ms;
  background-color: #FBD820;
  color: #29286D;
  box-shadow: 10px 10px 12px 0 rgba(0, 0, 0, 0.2);
  max-width: 200px;
}

.contact-modal .contact-form input[type="submit"]:hover {
  border: solid 4px white;
  border-width: 0 1px 4px 1px;
  background-color: #f0cb04;
}

.contact-modal .contact-form input[type="submit"]:active {
  background-color: #cbab04;
}

.contact-modal .first-step.hidden {
  display: none;
}

.contact-modal .success {
  display: none;
}

.contact-modal .success-image {
  margin-bottom: 52px;
}

.contact-modal .success.active {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact-modal .success .Paragraph {
  max-width: 222px;
  text-align: center;
  margin-bottom: 67px;
  color: #555555;
}

.contact-modal .success .social-links {
  display: flex;
  margin-bottom: 80px;
}

.contact-modal .success .social-links .facebook {
  width: 62px;
  height: 62px;
  background: url("/images/social/grey/facebook.svg") no-repeat center;
}

.contact-modal .success .social-links .instagram {
  width: 62px;
  height: 62px;
  background: url("/images/social/grey/instagram.svg") no-repeat center;
}

.contact-modal .success .social-links .linkedin {
  width: 62px;
  height: 62px;
  background: url("/images/social/grey/linkedin.svg") no-repeat center;
}

.contact-modal .success .social-links .twitter {
  width: 62px;
  height: 62px;
  background: url("/images/social/grey/twitter.svg") no-repeat center;
}

.SocialLinks {
  display: flex;
  justify-content: center;
}

.SocialLinks .link {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 300ms ease-in-out;
  transition-property: transform, opacity;
}

.SocialLinks .link:hover {
  opacity: 0.8;
  transform: scale(1.1);
}

.SocialLinks .link.facebook {
  background-image: url("/images/social/facebook.svg");
}

.SocialLinks .link.twitter {
  background-image: url("/images/social/twitter.svg");
}

.SocialLinks .link.instagram {
  background-image: url("/images/social/instagram.svg");
}

.SocialLinks .link.linkedin {
  background-image: url("/images/social/linkedin.svg");
}

.side-menu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 90%;
  bottom: 0;
  height: 100vh;
  transition: all .4s ease;
  z-index: 999;
}

.side-menu.blue {
  background: #374E85;
}

.side-menu.grey {
  background: #3B4253;
}

.side-menu.darkgrey {
  background: #33363D;
}

.side-menu.active {
  right: 0;
}

@media (min-width: 1000px) {
  .side-menu {
    width: 460px;
  }
}

.side-menu .navicon {
  width: 20px;
  height: 20px;
  padding: 0;
  border: 0;
  background: url("/images/cross.svg") center no-repeat;
  cursor: pointer;
  outline: 0;
}

.side-menu .token-price {
  position: relative;
  font-size: 20px;
}

.side-menu .token-price:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  right: -47px;
  width: 26px;
  height: 26px;
  background: url("/images/token-icon.svg") center no-repeat;
}

@media (min-width: 1000px) {
  .side-menu .token-price {
    font-size: 24px;
  }
}

.side-menu .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: Muli, system-ui, sans-serif;
  font-size: 24px;
  color: #fff;
  padding: 43px 60px 60px;
}

.side-menu .container .logo {
  width: 121px;
  display: none;
}

@media (min-width: 1000px) {
  .side-menu .container .logo {
    display: block;
  }
}

.side-menu .container .logo .text {
  fill: #fff;
}

.side-menu hr {
  width: 45px;
  height: 3px;
  background: #fff;
  border-style: none;
  text-align: left;
  margin: 25px 0 20px;
}

@media (min-width: 1000px) {
  .side-menu hr {
    margin: 40px 0 50px;
  }
}

.side-menu .menu-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 50px;
}

@media (min-width: 1000px) {
  .side-menu .menu-header {
    justify-content: space-between;
  }
}

.side-menu .menu-links {
  display: flex;
  flex-direction: column;
}

.side-menu .menu-links a {
  font-family: Muli, system-ui, sans-serif;
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
  text-decoration: none;
}

@media (min-width: 1000px) {
  .side-menu .menu-links a {
    margin-bottom: 30px;
    font-size: 24px;
  }
}

.side-menu .menu-links a.active {
  font-weight: 600;
}

.side-menu .lang-picker {
  margin: 50px 0 62px 0;
  font-size: 24px;
  margin: 30px 0 30px 0;
}

.side-menu .lang-picker a {
  font-size: 18px;
  color: #fff;
  width: 43px;
  text-align: left;
  text-decoration: none;
  padding: 0 15px 20px 0;
  margin-right: 15px;
  text-transform: uppercase;
}

.side-menu .lang-picker a.active {
  font-weight: 600;
  border-bottom: 1px solid #fff;
}

@media (min-width: 1000px) {
  .side-menu .lang-picker {
    margin: 50px 0 62px 0;
  }
}

.side-menu .newsletter {
  width: 100%;
  display: none;
}

@media (min-width: 1000px) {
  .side-menu .newsletter {
    display: block;
  }
}

.side-menu .newsletter .label {
  font-size: 18px;
  display: none;
}

@media (min-width: 1000px) {
  .side-menu .newsletter .label {
    display: block;
  }
}

.side-menu .newsletter .input-group {
  position: relative;
  margin: 37px auto 17px;
}

.side-menu .newsletter .input {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 13px;
  font-family: Muli, system-ui, sans-serif;
  font-size: 16px;
  color: #fff;
  border: solid 2px #fff;
  background: transparent;
  border-radius: 25px;
  text-decoration: none;
  outline: 0;
}

.side-menu .newsletter .input::placeholder {
  color: #fff;
}

.side-menu .newsletter .Button {
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
}

.side-menu .social-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-left: -15px;
}

@media (min-width: 1000px) {
  .side-menu .social-links {
    margin-top: 20px;
    justify-content: space-around;
    margin-left: inherit;
  }
}

.side-menu .social-links .link {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
}

.side-menu .social-links .link.facebook {
  background-image: url("/images/social/sidemenu/facebook.svg");
}

.side-menu .social-links .link.twitter {
  background-image: url("/images/social/sidemenu/twitter.svg");
}

.side-menu .social-links .link.instagram {
  background-image: url("/images/social/sidemenu/instagram.svg");
}

.side-menu .social-links .link.linkedin {
  background-image: url("/images/social/sidemenu/linkedin.svg");
}

.mask-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  z-index: 10;
  pointer-events: none;
  background-color: rgba(34, 48, 81, 0.5);
  transition: all .3s ease;
  z-index: 100;
}

.mask-overlay.active {
  opacity: 1;
  pointer-events: all;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  will-change: transform;
}

.header.fixed {
  position: fixed;
  transform: translateY(-100%);
  background-color: white;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
}

.header.fixed .container {
  padding: 24px;
}

@media (min-width: 1580px) {
  .header.fixed .container {
    padding: 24px 0;
  }
}

.header.fixed .container .logo .text {
  fill: #555555;
}

.header.fixed .container .menu .link {
  color: #374E85;
}

.header.fixed .container .menu .Button {
  color: #374E85;
  border-color: currentColor;
}

.header.fixed.visible {
  transform: translateY(0);
  transition: all 200ms;
}

.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  max-width: 1580px;
  margin: 0 auto;
}

@media (min-width: 1000px) {
  .header .container {
    padding: 35px 24px;
  }
}

@media (min-width: 1580px) {
  .header .container {
    padding: 35px 0;
  }
}

.header .container .logo {
  flex: 0 0 82px;
  height: 25px;
  flex-basis: 121px;
  height: 36px;
}

.header .container .logo .text {
  fill: white;
}

@media (min-width: 1000px) {
  .header .container .logo {
    margin-right: auto;
  }
}

.header .container .menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  height: 105vh;
  opacity: 0;
  transform: translateY(-100%);
  transition: all 300ms ease-in-out;
}

@media (min-width: 1000px) {
  .header .container .menu {
    display: block;
    position: static;
    display: block;
    padding: 0;
    height: auto;
    background: transparent;
    opacity: 1;
    transform: translateY(0);
    z-index: 999;
  }
}

.header .container .menu .link.link {
  display: inline-block;
  margin: 30px 0;
  font-family: Muli, system-ui, sans-serif;
  font-size: 20px;
  font-weight: 800;
  color: #505050;
  text-decoration: none;
}

@media (min-width: 1000px) {
  .header .container .menu .link.link {
    color: white;
    margin: 0 45px 0 0;
    font-size: 16px;
  }
}

.header .container .menu .Button {
  display: none;
}

@media (min-width: 1000px) {
  .header .container .menu .Button {
    display: inline-flex;
  }
}

.header .container .menu .SocialLinks {
  display: none;
}

.header .container .navicon {
  width: 30px;
  height: 23px;
  padding: 0;
  border: 0;
  background: url("/images/navicon.svg") center no-repeat;
  cursor: pointer;
  outline: 0;
}

@media (min-width: 1000px) {
  .header .container .navicon {
    margin-left: 20px;
  }
}

.Hero {
  position: relative;
  width: 100%;
  padding: 80px 0 0;
  background: url("/images/background-line.svg") top no-repeat, linear-gradient(114deg, #374e85, #4387ff);
  box-shadow: 0 2px 100px 0 rgba(0, 0, 0, 0.22);
  color: white;
}

@media (min-width: 1000px) {
  .Hero {
    padding: 140px 0 0;
  }
}

.Hero::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  display: block;
  background: linear-gradient(-180deg, transparent, rgba(0, 0, 0, 0.2));
  height: 120px;
}

.Hero .container {
  max-width: 1580px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1580px) {
  .Hero .container {
    padding: 24px 0;
  }
}

@media (min-width: 1000px) {
  .Hero .container {
    flex-direction: row;
    align-items: normal;
  }
}

.Hero .title {
  font-family: Barlow, system-ui, sans-serif;
  font-weight: 800;
  font-size: 36px;
  line-height: 1.28;
  text-align: center;
  margin-bottom: 30px;
}

@media (min-width: 1000px) {
  .Hero .title {
    text-align: left;
    line-height: 1;
  }
}

@media (min-width: 1250px) {
  .Hero .title {
    font-size: 80px;
  }
}

.Hero .register {
  margin-right: 20px;
}

@media (min-width: 1000px) {
  .Hero .register {
    max-width: 666px;
  }
}

.Hero .register p {
  font-size: 18px;
}

@media (min-width: 1000px) {
  .Hero .register p {
    font-size: 24px;
  }
}

.Hero .register .disclaimer {
  margin-top: 10px;
  font-size: 16px;
  color: #fff;
}

@media (min-width: 1000px) {
  .Hero .register .disclaimer {
    margin-top: 34px;
    max-width: 452px;
  }
}

@media (min-width: 1250px) {
  .Hero .register .disclaimer {
    margin-top: 74px;
  }
}

.Hero .app-shot {
  position: relative;
  bottom: -28px;
}

.Hero .app-shot img {
  width: 100%;
  height: auto;
}

@media (min-width: 1000px) {
  .Hero .app-shot img {
    max-width: 698px;
  }
}

.social {
  background: #659dff;
  padding: 60px 0 48px;
}

@media (min-width: 1000px) {
  .social {
    padding: 40px 0 120px;
  }
}

.social .container {
  max-width: 1580px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1580px) {
  .social .container {
    padding: 24px 0;
  }
}

.social .container .inbest-logo {
  margin: 0 auto 20px;
  background: url("/images/inbest.svg") center no-repeat;
  background-size: cover;
  height: 100px;
  width: 100px;
}

.social .container .telegram {
  margin-top: 48px;
}

.social .container .telegram::before {
  content: '';
  display: block;
  background: url("/images/telegram-icon.svg") center no-repeat;
  background-size: cover;
  margin: 5px 16px 0 0;
  width: 23px;
  height: 20px;
}

.features {
  padding: 60px 0 0;
  background: url("/images/line01.png") no-repeat 20% 230px;
}

@media (min-width: 1000px) {
  .features {
    padding: 100px 0 100px;
  }
}

.features .container {
  max-width: 1580px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1580px) {
  .features .container {
    padding: 24px 0;
  }
}

.features .container .Title {
  max-width: 816px;
  font-size: 32px;
  color: #555555;
  line-height: 1.28;
}

@media (min-width: 1000px) {
  .features .container .Title {
    font-size: 62px;
  }
}

.features .container .features-title {
  text-align: center;
}

.features .list {
  display: block;
  width: 100%;
  margin-top: 55px;
}

@media (min-width: 1000px) {
  .features .list {
    margin-top: 90px;
  }
}

.features .list .item {
  padding: 30px 0;
}

@media (min-width: 1000px) {
  .features .list .item {
    padding: 50px 0;
  }
}

.features .list .item .contain {
  flex-direction: column-reverse;
}

@media (min-width: 1000px) {
  .features .list .item .contain {
    flex-direction: inherit;
  }
}

@media (min-width: 1000px) {
  .features .list .item:nth-child(even) .contain {
    flex-direction: row-reverse;
  }
}

@media (min-width: 1000px) {
  .features .list .item:nth-child(even) .image {
    padding-left: 0;
  }
}

.features .list .contain {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1020px;
  align-items: center;
  justify-content: space-around;
  color: #555555;
  line-height: 1.3;
}

@media (min-width: 1000px) {
  .features .list .contain {
    flex-direction: row;
  }
}

.features .list .block {
  max-width: 500px;
  padding: 24px;
  text-align: center;
}

@media (min-width: 1000px) {
  .features .list .block {
    text-align: left;
    padding: 0 50px;
  }
}

.features .list .subtitle {
  font-family: Barlow, system-ui, sans-serif;
  font-size: 26px;
  font-weight: 800;
}

.features .list .subtext {
  font-size: 20px;
}

.features .list .image {
  width: 100%;
}

@media (min-width: 425px) {
  .features .list .image {
    max-width: 322px;
  }
}

@media (min-width: 1000px) {
  .features .feature1 {
    background: url("/images/line02.png") no-repeat 90% 0;
  }
  .features .feature2 {
    background: url("/images/line03.png") no-repeat 10% 50%;
  }
  .features .feature3 {
    background: url("/images/line04.png") no-repeat 75% 0, url("/images/line05.png") no-repeat 10% 90%;
  }
}

.slideshow {
  padding-top: 60px;
  background: #fff;
  color: #555555;
}

@media (min-width: 1000px) {
  .slideshow {
    padding-top: 140px;
  }
}

.slideshow .container {
  max-width: 1580px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

@media (min-width: 1580px) {
  .slideshow .container {
    padding: 24px 0;
  }
}

.slideshow .container .Title {
  max-width: 816px;
  font-size: 32px;
}

@media (min-width: 1000px) {
  .slideshow .container .Title {
    font-size: 62px;
  }
}

.slideshow .container .Paragraph {
  text-align: center;
}

@media (min-width: 1000px) {
  .slideshow .container .Paragraph {
    padding: 0 30px;
  }
}

.slideshow .list {
  width: 100%;
  max-width: 1110px;
  margin: 44px auto;
}

@media (min-width: 1024px) {
  .slideshow .list {
    display: grid;
    grid-template-columns: repeat(3, calc(33.333% - 15px));
    grid-gap: 25px;
    margin: 100px auto 70px;
  }
}

@media (min-width: 1200px) {
  .slideshow .list {
    grid-template-columns: repeat(3, 350px);
    grid-gap: 30px;
  }
}

.slideshow .list .card {
  width: 100%;
  height: 503px;
  margin: 30px 0;
  padding: 50px 45px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
}

@media (min-width: 1200px) {
  .slideshow .list .card {
    height: 525px;
    margin: 0;
    padding: 45px;
  }
}

.slideshow .list .card .subtitle {
  font-family: Barlow, system-ui, sans-serif;
  font-size: 24px;
  font-weight: 800;
  color: #555555;
  margin: 50px 0 30px;
}

.slideshow .list .card .image {
  width: 110px;
  height: 110px;
  margin: 0 auto;
}

.slideshow .list .card .subtext {
  max-width: 360px;
  margin: 0 auto;
  font-family: Muli, system-ui, sans-serif;
  font-size: 18px;
  line-height: 1.67;
  color: #555555;
}

@media (min-width: 1000px) {
  .slideshow .list .card .subtext {
    max-width: 100%;
  }
}

.footer {
  padding: 60px 30px;
}

@media (min-width: 1580px) {
  .footer {
    padding: 60px 0;
  }
}

.footer .container {
  max-width: 1110px;
  margin: 0 auto;
}

@media (min-width: 1000px) {
  .footer .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.footer .container .newsletter {
  margin-bottom: 0px;
  text-align: center;
}

@media (min-width: 1000px) {
  .footer .container .newsletter {
    margin-bottom: 0;
    text-align: left;
  }
}

.footer .container .newsletter .label {
  display: block;
  font-family: Muli, system-ui, sans-serif;
  font-size: 20px;
  color: #374E85;
}

@media (min-width: 1000px) {
  .footer .container .newsletter .label {
    margin: 0 0 20px 10px;
  }
}

.footer .container .newsletter .input {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  padding: 13px;
  margin: 37px auto 17px;
  font-family: Muli, system-ui, sans-serif;
  font-size: 16px;
  color: #374E85;
  border: solid 2px #374E85;
  border-radius: 25px;
  text-decoration: none;
  outline: 0;
}

@media (min-width: 1000px) {
  .footer .container .newsletter .input {
    margin: 0 20px 0 0;
  }
}

.footer .container .newsletter .input::placeholder {
  color: rgba(80, 80, 80, 0.5);
}

.footer .container .newsletter .result {
  display: block;
  font-family: Muli, system-ui, sans-serif;
  font-size: 16px;
  color: #555555;
}

@media (min-width: 1000px) {
  .footer .container .newsletter .result {
    margin: 20px 0 20px 10px;
  }
}
