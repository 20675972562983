.slideshow {
  padding-top: 60px;
  background: #fff;
  color: $grey;

  @include desktop {
    padding-top: 140px;
  }

  .container {
    @include container-wide;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    .Title {
      max-width: 816px;
      font-size: 32px;
      @include desktop {
        font-size: 62px;
      }
    }

    .Paragraph {
      text-align: center;
      @include desktop {
        padding: 0 30px;
      }
    }
  }

  .list {
    width: 100%;
    max-width: 1110px;
    margin: 44px auto;

    @media (min-width: 1024px){
      display: grid;
      grid-template-columns: repeat(3, calc(33.333% - 15px));
      grid-gap: 25px;
      margin: 100px auto 70px;
    }

    @media (min-width: 1200px){
      grid-template-columns: repeat(3, 350px);
      grid-gap: 30px;
    }

    .card {
      width: 100%;
      height: 503px;
      margin: 30px 0;
      padding: 50px 45px;
      border-radius: 10px;
      background-color: white;
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15);
      text-align: center;

      @media (min-width: 1200px) {
        height: 525px;
        margin: 0;
        padding: 45px;
      }

      .subtitle {
        font-family: $barlow;
        font-size: 24px;
        font-weight: 800;
        color: $grey;
        margin: 50px 0 30px; 
      }

      .image {
        width: 110px;
        height: 110px;
        margin: 0 auto;
      }

      .subtext {
        max-width: 360px;
        margin: 0 auto;
        font-family: $muli;
        font-size: 18px;
        line-height: 1.67;
        color: $grey;

        @include desktop {
          max-width: 100%;
        }

      }

    }

  }

}