.Hero {
  position: relative;
  width: 100%;
  padding: 80px 0 0;
  background:  url('/images/background-line.svg') top no-repeat, linear-gradient(114deg, #374e85, #4387ff);
  box-shadow: 0 2px 100px 0 rgba(0, 0, 0, 0.22);
  color: white;
  
  @include desktop {
    padding: 140px 0 0;
  }

  &::after {
    content:'';
    position: absolute;
    bottom: 0;
    width: 100%;
    display: block;
    background: linear-gradient(-180deg, transparent, rgba(0,0,0, .2));
    height: 120px;
  }

  .container {
    @include container-wide;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    @include desktop {
      flex-direction: row;
      align-items: normal;
    }
  }

  .title {
    font-family: $barlow;
    font-weight: 800;
    font-size: 36px;
    line-height: 1.28;
    text-align: center;
    margin-bottom: 30px;

    @include desktop {
      text-align: left;  
      line-height: 1;
    }
    
    @media (min-width: 1250px) {
      font-size: 80px;
    }
  }
  
  .register {
    margin-right: 20px;
    
    @include desktop {
      max-width: 666px;
    }

    p {
      font-size: 18px;

      @include desktop {
        font-size: 24px;
      }
    }

    .disclaimer {
      margin-top: 10px;
      font-size: 16px;
      color: #fff;
      
      @include desktop {
        margin-top: 34px;
        max-width: 452px;
      }

      @media (min-width: 1250px) {
        margin-top: 74px;
      }
    }

  }

  .app-shot {
    position: relative;
    bottom: -28px;

    img {
      width: 100%;
      height: auto;
      @include desktop {
        max-width: 698px;
      }
    }

  }
}
