.footer {
  padding: 60px 30px;

  @include desktop-wide {
    padding: 60px 0;
  }

  .container {
    max-width: 1110px;
    margin: 0 auto;

    @include desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .newsletter {
      margin-bottom: 0px;
      text-align: center;

      @include desktop {
        margin-bottom: 0;
        text-align: left;
      }

      .label {
        display: block;
        font-family: $muli;
        font-size: 20px;
        color: $darkblue;

        @include desktop {
          margin: 0 0 20px 10px;
        }

      }

      .input {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        padding: 13px;
        margin: 37px auto 17px;
        font-family: $muli;
        font-size: 16px;
        color: $darkblue;
        border: solid 2px $darkblue;
        border-radius: 25px;
        text-decoration: none;
        outline: 0;

        @include desktop {
          margin: 0 20px 0 0;
        }

        &::placeholder {
          color: rgba($darkgrey, .5);
        }

      }

      .result {
        display: block;
        font-family: $muli;
        font-size: 16px;
        color: $grey;

        @include desktop {
          margin: 20px 0 20px 10px;
        }

      }

    }

  }


}
