.mask-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  z-index: 10;
  pointer-events: none;
  background-color: rgba(34, 48, 81, 0.5);
  transition: all .3s ease;
  z-index: 100;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  will-change: transform;

  &.fixed {
    position: fixed;
    transform: translateY(-100%);
    background-color: white;
    box-shadow: 0 2px 8px 0 rgba(black, .08);

    .container {
      padding: 24px;

      @include desktop-wide {
        padding: 24px 0;
      }

      .logo .text {
        fill: $grey;
      }

      .menu {

        .link {
          color: $darkblue;
        }

        .Button {
          color: $darkblue;
          border-color: currentColor;
        }

      }

    }

    &.visible {
      transform: translateY(0);
      transition: all 200ms;
    }

  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    width: 100%;
    max-width: 1580px;
    margin: 0 auto;

    @include desktop {
      padding: 35px 24px;
    }

    @include desktop-wide {
      padding: 35px 0;
    }

    .logo {
      flex: 0 0 82px;
      height: 25px;
      flex-basis: 121px;
      height: 36px;

      .text {
        fill: white;
      }

      @include desktop {
        margin-right: auto;
      }
    }

    .menu {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 100px;
      height: 105vh;
      opacity: 0;
      transform: translateY(-100%);
      transition: all 300ms ease-in-out;

      @include desktop {
        display: block;
        position: static;
        display: block;
        padding: 0;
        height: auto;
        background: transparent;
        opacity: 1;
        transform: translateY(0);
        z-index: 999;
      }

      // i'm sorry Harry
      .link.link {
        display: inline-block;
        margin: 30px 0;
        font-family: $muli;
        font-size: 20px;
        font-weight: 800;
        color: $darkgrey;
        text-decoration: none;

        @include desktop {
          color: white;
          margin: 0 45px 0 0;
          font-size: 16px;
        }

      }

      .Button {
        display: none;

        @include desktop {
          display: inline-flex;
        }

      }

      .SocialLinks {
        display: none;
      }

    }

    .navicon {
      width: 30px;
      height: 23px;
      padding: 0;
      border: 0;
      background: url('/images/navicon.svg') center no-repeat;
      cursor: pointer;
      outline: 0;

      @include desktop {
        margin-left: 20px;
      }

    }

  }

}
