*,
*:before,
*:after {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: inherit;
  margin: 0;
  font-family: $muli;
  font-weight: 400;
}
p {
  margin: 0;
  padding: 0;
}

.slick-slide {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.slick-dots {

  li button {
    &::before {
      font-size: 40px;
      color: #fff;
    }
  }
  
  li.slick-active {
    button {
      &::before {
        color: #fff;
        opacity: 1;
      }
    }
  }

}
