.social {
  background: #659dff;
  padding: 60px 0 48px;
  @include desktop {
    padding: 40px 0 120px;
  }

  .container {
    @include container-wide;
    display: flex;
    flex-direction: column;
    align-items: center;

    .inbest-logo {
      margin: 0 auto 20px;
      background: url('/images/inbest.svg') center no-repeat;
      background-size: cover;
      height: 100px;
      width: 100px;
    }

    .telegram {
      margin-top: 48px;
      &::before {
        content: '';
        display: block;
        background: url('/images/telegram-icon.svg') center no-repeat;
        background-size: cover;
        margin: 5px 16px 0 0;
        width: 23px;
        height: 20px;
      }
    }
  }

}