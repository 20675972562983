.UsersCounter {
  margin-top: 35px;
  font-family: $muli;
  text-transform: uppercase;
  font-size: 16px;
  color: #fff;

  .counter {
    font-weight: 800;
  }
  
  .users {
    margin-left: 25px;
    display: none;

    @include desktop {
      display: inline-block;
    }

    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 13px;
      height: 13px;
      line-height: 13px;
      background: #ABFB20;
      border-radius: 100%;
      margin-right: 10px;
      margin-bottom: 2px;
    }
  }
}