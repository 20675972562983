@mixin tablet {
  @media (min-width: #{$tablet}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop}) {
    @content;
  }
}

@mixin desktop-wide {
  @media (min-width: #{$desktop-wide}) {
    @content;
  }
}

@mixin container-wide {
  max-width: $desktop-wide;
  margin: 0 auto;
  padding: 24px;

  @include desktop-wide {
    padding: 24px 0;
  }
}

@mixin wip {
  background: repeating-linear-gradient(
    135deg,
    yellow,
    yellow 20px,
    black 10px,
    black 40px
  );
}
