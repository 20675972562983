.contact-modal {
  position: fixed;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  padding: 90px 15px 42px;
  z-index: 9999;
  transition: all .3s ease;
  &.active {
    opacity: 1;
    pointer-events: all;
  }
  
  @media (min-width: 768px) {
    width: 740px;
    height: auto;
    left: 50%;
    top: 50%;
    right: inherit;
    bottom: inherit;
    border-radius: 12px;
    transform: translate(-50%, -50%);
    padding: 90px 150px 40px;
  }

  .Title {
    font-size: 32px;

    @include desktop {
      font-size: 62px;
    }
  }

  .close-icon {
    position: absolute;
    height: 20px;
    width: 20px;
    right: 35px;
    top: 35px;
    background: url('/images/close-icon.svg') no-repeat center;
    z-index: 99999;
  }

  .contact-form {
    .group {
      position: relative;
      display: inline-block;
      font-family: $muli;
      width: 100%;
      margin: 15px 0;
      
      @include desktop {
        margin: 20px 0;
      }
    }

    label {
      position: absolute;
      top: 50%;
      left: 0;  
      font-size: 18px;
      color: #bababa;
      transform: translateY(-50%);
      transition: all .15s ease-out 0s;
    }

    input {
      font-size: 18px;
      display: inline-block;
      background: none;
      padding: 5px 0;
      font-size: 18px;
      color: $grey;
      width: 100%;

      &[type="email"],
      &[type="text"] {
        border: none;
        border-bottom: 1px solid #bababa;
      }

      &:focus,
      &:active {
        outline: none;
      }

      &[disabled] {
        pointer-events: none;
        opacity: 0.3;
    
        &.success {
          color: green;
        }    
      }

      &.error {
        border-bottom: 1px solid #ff4343;
      }
    }

    #mc_embed_signup div.mce_inline_error {
      color: #ff4343;
      background: none;
      font-size: 14px;
      font-weight: 300;
      margin: 5px 0;
      padding: 0;
      height: 0;
    }

    #mc_embed_signup input.mce_inline_error {
      border-color: #ff4343;
    }
    
    .input-text {
      border: none;
      border-bottom: 1px solid #bababa;
    }

    .group input:focus + label,
    .group input.has-value + label {
      font-size: 14px;
      top: -25%;
    }



    select {
      font-family: $muli;
      font-size: 18px;
      width: 100%;
      padding: 5px 0;
      border-radius: 0;
      background: none;
      padding: 5px 0;
      margin: 0;
      outline:0;
      border: 0;
      color: #555555;
      border-bottom: 1px solid #bababa;
      -webkit-appearance: none;
    }

    .dropdown {
      position: relative;
      top: 30%;
    }
    
    .dropdown-group {
      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 20px;
        width: 20px;
        right: 0;
        top: 25%;
        background: url('/images/arrow_down.svg') no-repeat center;
      }
    }

    
    .dropdown-label.has-value {
      top: -30%;
    }

    .response {
      margin: 20px 0;
    }

    input[type="submit"] {
      display: block;
      min-width: 214px;
      padding: 13px 26px;
      margin: 50px auto 23px;
      font-family: $muli;
      font-weight: 800;
      font-size: 16px;
      color: $darkgrey;
      border: solid 2px transparent;
      border-radius: 32px;
      text-decoration: none;
      outline: 0;
      transition: all 200ms;
      background-color: $orange;
      color: #29286D;
      box-shadow: 10px 10px 12px 0 rgba(black, 0.2);
      max-width: 200px;
  
      &:hover {
        border: solid 4px white;
        border-width: 0 1px 4px 1px;
        background-color: darken($orange, 7.5%);
      }
  
      &:active {
        background-color: darken($orange, 15%);
      }

    }

  }

  .first-step.hidden {
    display: none;
  }

  .success {
    display: none;

    &-image {
      margin-bottom: 52px;
    }
    
    &.active {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .Paragraph {
      max-width: 222px;
      text-align: center;
      margin-bottom: 67px;
      color: #555555;
    }

    .social-links {
      display: flex;
      margin-bottom: 80px;
      
      .facebook {
        width: 62px;
        height: 62px;;
        background: url('/images/social/grey/facebook.svg') no-repeat center;
      }
      .instagram {
        width: 62px;
        height: 62px;;
        background: url('/images/social/grey/instagram.svg') no-repeat center;
      }
      .linkedin {
        width: 62px;
        height: 62px;;
        background: url('/images/social/grey/linkedin.svg') no-repeat center;
      }
      .twitter {
        width: 62px;
        height: 62px;;
        background: url('/images/social/grey/twitter.svg') no-repeat center;
      }

    }

  }

}

