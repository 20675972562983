.SocialLinks {
  display: flex;
  justify-content: center;

  .link {
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 300ms ease-in-out;
    transition-property: transform, opacity;

    &:hover {
      opacity: 0.8;
      transform: scale(1.1);
    }

    &.facebook {
      background-image: url('/images/social/facebook.svg');
    }

    &.twitter {
      background-image: url('/images/social/twitter.svg');
    }

    &.instagram {
      background-image: url('/images/social/instagram.svg');
    }

    &.linkedin {
      background-image: url('/images/social/linkedin.svg');
    }

  }

}
