.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 214px;
  padding: 13px 26px;
  font-family: $muli;
  font-weight: 800;
  font-size: 16px;
  color: $darkgrey;
  border: solid 2px transparent;
  border-radius: 32px;
  text-decoration: none;
  outline: 0;
  transition: all 200ms;

  &[disabled] {
    pointer-events: none;
    opacity: 0.3;

    &.success {
      color: green;
    }

  }

  &.auto {
    min-width: auto;
  }

  &.primary {
    background-color: $orange;
    color: #29286D;
    box-shadow: 10px 10px 12px 0 rgba(black, 0.2);

    &:hover {
      border: solid 4px white;
      border-width: 0 0 4px 0;
      background-color: darken($orange, 7.5%);
    }

    &:active {
      background-color: darken($orange, 15%);
    }    
  }

  &.secondary {
    color: white;
    border-color: currentColor;

    &:hover {
      border-bottom-width: 4px;
    }

    &:active {
      background-color: $blue;
    }    
  }

  &.tertiary {
    color: $darkblue;
    border-color: currentColor;

    &:hover {
      border-bottom-width: 4px;
    }

    &:active {
      background-color: $blue;
    }    
  }

  &.big {
    min-width: 282px;
    padding: 18px 13px;
    font-size: 20px;
    border-radius: 32px;

    @include desktop {
      min-width: 300px;
    }

  }
  
}
