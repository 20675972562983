// Fonts
$base-fonts: system-ui, sans-serif;
$barlow: Barlow, #{$base-fonts};
$muli: Muli, #{$base-fonts};

// Breakpoints
$tablet: 480px;
$desktop: 1000px;
$desktop-wide: 1580px;

// Colors
$orange: #FBD820;
$darkblue: #374E85;
$darkgrey: #505050;
$grey: #555555;
$blue: #4387FF;

// Sidemenu
$sidemenu-grey: #3B4253;
$sidemenu-darkgrey: #33363D;
$sidemenu-blue: #374E85;
