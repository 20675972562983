.side-menu {
  position: fixed;
  top: 0;
  right: -100%;
  width: 90%;
  bottom: 0;
  height: 100vh;
  transition: all .4s ease;
  z-index: 999;

  &.blue {
    background: $sidemenu-blue;
  }

  &.grey {
    background: $sidemenu-grey;
  }

  &.darkgrey {
    background: $sidemenu-darkgrey;
  }

  &.active {
    right: 0;
  }

  @include desktop {
    width: 460px;
  }

  .navicon {
    width: 20px;
    height: 20px;
    padding: 0;
    border: 0;
    background: url('/images/cross.svg') center no-repeat;
    cursor: pointer;
    outline: 0;
  }

  .token-price {
    position: relative;
    font-size: 20px;
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      right: -47px;
      width: 26px;
      height: 26px;
      background: url('/images/token-icon.svg') center no-repeat;
    }

    @include desktop {
      font-size: 24px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: $muli;
    font-size: 24px;
    color: #fff;
    padding: 43px 60px 60px;

    .logo  {
      width: 121px;
      display: none;
      @include desktop {
        display: block;
      }

      .text {
        fill: #fff;
      }
    }
  }

  hr {
    width: 45px;
    height: 3px;
    background: #fff;
    border-style: none;
    text-align: left;
    margin: 25px 0 20px;
    @include desktop {
      margin: 40px 0 50px;

    }
  }

  .menu-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 50px;
    @include desktop {
      justify-content: space-between;
    }
  }

  .menu-links {
    display: flex;
    flex-direction: column;

    a {
      font-family: $muli;
      font-size: 18px;
      color: #fff;
      margin-bottom: 10px;
      text-decoration: none;

      @include desktop {
        margin-bottom: 30px;
        font-size: 24px;
      }

      &.active {
        font-weight: 600;
      }
    }
  }

  .lang-picker {
    margin: 50px 0 62px 0;
    font-size: 24px;
    margin: 30px 0 30px 0;

    a {
      font-size: 18px;
      color: #fff;
      width: 43px;
      text-align: left;
      text-decoration: none;
      padding: 0 15px 20px 0;
      margin-right: 15px;
      text-transform: uppercase;
      &.active {
        font-weight: 600;
        border-bottom: 1px solid #fff;
      }
    }

    @include desktop {
      margin: 50px 0 62px 0;
    }

  }

  .newsletter {
    width: 100%;
    display: none;

    @include desktop {
      display: block;
    }
    .label {
      font-size: 18px;
      display: none;
      @include desktop {
        display: block;
      }
    }

    .input-group {
      position: relative;
      margin: 37px auto 17px;
    }

    .input {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 13px;
      font-family: $muli;
      font-size: 16px;
      color: #fff;
      border: solid 2px #fff;
      background: transparent;
      border-radius: 25px;
      text-decoration: none;
      outline: 0;
      &::placeholder {
        color: #fff;
      }
    }

    .Button {
      position: absolute;
      right: 0;
      top: 0;
      color: #fff;
    }

  }

  .social-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-left: -15px;

    @include desktop {
      margin-top: 20px;
      justify-content: space-around;
      margin-left: inherit;
    }

    .link {
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-position: center;

      &.facebook {
        background-image: url('/images/social/sidemenu/facebook.svg');
      }
      &.twitter {
        background-image: url('/images/social/sidemenu/twitter.svg');
      }

      &.instagram {
        background-image: url('/images/social/sidemenu/instagram.svg');
      }

      &.linkedin {
        background-image: url('/images/social/sidemenu/linkedin.svg');
      }

    }

  }

}
