.hero-form {
  position: relative;
  height: 80px;
  margin-top: 41px;

  input {
    height: 60px;
    width: 100%;
    font-family: $muli;
    font-size: 15px;
    color: #9b9b9b;
    letter-spacing: 0.5px;
    border-radius: 40px;
    border: 0;
    outline: 0;
    padding: 21px 10px 21px 36px;

    @media (min-width: 1250px) {
      height: 100%;
      font-size: 21px;
    }
  }



  .register-button {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 50px;
    border-radius: 40px;

    // Hacky magic to avoid text content
    @media (max-width: 1249px) {
      min-width: 75px;
      height: 60px;
      text-indent: -100px;
      color: $orange;
      overflow: hidden;

      &::before {
        content: '';
        display: block;
        width: 23px;
        height: 18px;
        background: url('/images/email-icon.svg');
      }
    }
  }

}
.hero-form-response{
  margin-top:10px;
  margin-left:10px;
}
