.Title {
  margin: 0 0 24px;
  font-family: $barlow;
  font-weight: 800;
  font-size: 24px;
  text-align: center;
  line-height: 34px;
  
  &.darkgrey {
    color: $darkgrey;
  }
  
  &.white {
    color: white;
  }

  @include desktop {
    font-family: $barlow;
    font-weight: 800;
    font-size: 62px;
    color: $grey;
    letter-spacing: 0;
    line-height: 80px;
  }
}
