.features {
  padding: 60px 0 0;
  background: url('/images/line01.png') no-repeat 20% 230px;
  
  @include desktop {
    padding: 100px 0 100px;
  }

  .container {
    @include container-wide;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .Title {
      max-width: 816px;
      font-size: 32px;
      color: #555555;
      line-height: 1.28;
      
      @include desktop {
        font-size: 62px;
      }
    }

    .features-title {
      text-align: center;
    }
  }
  
  .list{
    display: block;
    width: 100%;
    margin-top: 55px;

    @include desktop {
      margin-top: 90px;
    }

    .item {
      padding: 30px 0;
      
      @include desktop {
        padding: 50px 0;
      }

      .contain {
        flex-direction: column-reverse;

        @include desktop {
          flex-direction: inherit;
        }
      }

      &:nth-child(even) 
      .contain {
        @include desktop {
          flex-direction: row-reverse;
        }
      }

      &:nth-child(even) 
      .image {
        @include desktop {
          padding-left: 0;
        }
      }
    }

    .contain {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      max-width: 1020px;
      align-items: center;
      justify-content: space-around;
      color: #555555;
      line-height: 1.3;

      @include desktop {
        flex-direction: row;
      }
    }

    .block {
      max-width: 500px;
      padding: 24px;
      text-align: center;

      @include desktop {
        text-align: left;
        padding: 0 50px;
      }
    }

    .subtitle {
      font-family: $barlow;
      font-size: 26px;
      font-weight: 800;
    }

    .subtext {
      font-size: 20px;
    }

    .image {
      width: 100%;

      @media (min-width: 425px) {
        max-width: 322px;
      }
      
    }    
  }
  
  // Features background lines //
  @include desktop {
    .feature1 {
      background: url('/images/line02.png') no-repeat 90% 0;
    }
    .feature2 {
      background: url('/images/line03.png') no-repeat 10% 50%;
    }
    .feature3 {
      background: url('/images/line04.png') no-repeat 75% 0, url('/images/line05.png') no-repeat 10% 90%;
    }
  }
}